<template>
  <div>
    <div class="chronometer-container">
      <div class="chronometer-container__leyend-container">
        {{ hasTournamentStarted ? 'Final ' : 'Camino a ' }}
        <span class="chronometer-container__leyend-container__bold">Qatar 2022</span>
      </div>
      <div class="chronometer-container__container-two">
        <div class="chronometer-container__container-two__clock-container">
          <div class="chronometer-container__container-two__clock-container__ballcontainer">
            <div class="chronometer-container__container-two__clock-container__ballcontainer__ball">
              <img src="/images/BallChronometer.png" />
            </div>
            <div class="chronometer-container__container-two__clock-container__ballcontainer__diamond">
              <img src="/images/SmallDiamondChronometer.png" />
            </div>
            <div class="chronometer-container__container-two__clock-container__ballcontainer__diamond">
              <img src="/images/SmallDiamondChronometer.png" />
            </div>
            <div class="chronometer-container__container-two__clock-container__ballcontainer__diamond">
              <img src="/images/SmallDiamondChronometer.png" />
            </div>
          </div>
          <div class="chronometer-container__container-two__clock-container__dayscontainer">
            <div class="chronometer-container__container-two__clock-container__dayscontainer__num" id="dayValue">
              {{ days }}
            </div>
            <div class="chronometer-container__container-two__clock-container__dayscontainer__leyend">DÍAS</div>
          </div>
          <div class="chronometer-container__container-two__clock-container__diamondcontainer">
            <img src="/images/DiamondChronometer.png" />
          </div>
          <div class="chronometer-container__container-two__clock-container__hourscontainer">
            <div class="chronometer-container__container-two__clock-container__hourscontainer__num" id="hourValue">
              {{ hours }}
            </div>
            <div class="chronometer-container__container-two__clock-container__hourscontainer__leyend">HORAS</div>
          </div>
          <div class="chronometer-container__container-two__clock-container__diamondcontainer">
            <img src="/images/DiamondChronometer.png" />
          </div>
          <div class="chronometer-container__container-two__clock-container__minutescontainer">
            <div class="chronometer-container__container-two__clock-container__minutescontainer__num" id="minValue">
              {{ minutes }}
            </div>
            <div class="chronometer-container__container-two__clock-container__minutescontainer__leyend">MINUTOS</div>
          </div>
          <div class="chronometer-container__container-two__clock-container__ballcontainer">
            <div class="chronometer-container__container-two__clock-container__ballcontainer__diamond">
              <img src="/images/SmallDiamondChronometer.png" />
            </div>
            <div class="chronometer-container__container-two__clock-container__ballcontainer__diamond">
              <img src="/images/SmallDiamondChronometer.png" />
            </div>
            <div class="chronometer-container__container-two__clock-container__ballcontainer__diamond">
              <img src="/images/SmallDiamondChronometer.png" />
            </div>
            <div class="chronometer-container__container-two__clock-container__ballcontainer__ball">
              <img src="/images/BallChronometer.png" />
            </div>
          </div>
        </div>
        <div class="chronometer-container__container-two__sponsor-container">
          <div
            class="chronometer-container__container-two__sponsor-container__sponsor"
            :class="custom.partner_logo != '' ? 'border-none' : ''"
          >
            <template v-if="custom.partner_logo != ''">
              <img
                @click="clickSponsor"
                :src="custom.partner_logo"
                :class="custom.partner_link != '' ? 'cursor-set' : ''"
              />
            </template>
            <template v-else>
              Patrocinador /&nbsp;
              <span class="chronometer-container__container-two__sponsor-container__sponsor__bold">Tu marca aquí</span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import axios from 'axios';
export default {
  name: 'Chronometer',
  props: {
    custom: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      timeToQatar: null,
      finalGameDateTime: '2022-12-18T15:00:00.000Z',
      tournamentStartDate: '2022-11-20T16:00:00.000Z',
      link: 'https://gsworldcup2022.s3.us-west-2.amazonaws.com/countdown.json',
    };
  },
  computed: {
    hasTournamentStarted() {
      return new Date().getTime() >= new Date(this.tournamentStartDate).getTime();
    },
  },
  methods: {
    clickSponsor() {
      if (this.custom.partner_link != '') {
        window.open(this.custom.partner_link, '_blank');
      }
    },
    getCountdown() {
      let countDownDate = new Date(
        this.hasTournamentStarted ? this.finalGameDateTime : this.tournamentStartDate,
      ).getTime();
      let x = setInterval(function() {
        let nowMillis = new Date().getTime();
        let distance = countDownDate - nowMillis;
        this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

        document.getElementById('dayValue').innerHTML = this.days < 10 ? '0' + this.days : this.days;
        document.getElementById('hourValue').innerHTML = this.hours < 10 ? '0' + this.hours : this.hours;
        document.getElementById('minValue').innerHTML = this.minutes < 10 ? '0' + this.minutes : this.minutes;
        if (distance < 0) {
          clearInterval(x);
          document.getElementById('dayValue').innerHTML = '00';
          document.getElementById('hourValue').innerHTML = '00';
          document.getElementById('minValue').innerHTML = '00';
        }
      }, 1000);
    },
  },
  mounted() {
    this.getCountdown();
  },
};
</script>
<style lang="scss">
.border-none {
  border: none !important;
}
.cursor-set {
  cursor: pointer;
}
.chronometer-container {
  width: 100%;
  max-width: 1300px;
  height: 100px;
  display: inline-flex;

  margin: 0 auto;
  border-radius: 13px;
  background-color: #132839;
  @media screen and (max-width: 768px) {
    display: inline-block;
  }
  &__leyend-container {
    width: 30.95%;
    height: 100px;
    font-size: 18px;
    font-family: 'Avenir-Regular';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 100px;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    // background: red;
    @media screen and (max-width: 870px) {
      font-size: 16px;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      height: 30.6px;
      line-height: 42px;
    }
    @media screen and (max-width: 450px) {
      width: 100%;
      height: 20px;
      font-size: 12px;
      line-height: 23px;
    }
    &__bold {
      font-family: 'Avenir-Pro-Bold';
    }
  }
  &__container-two {
    display: inline-flex;
    width: 69.05%;
    @media screen and (max-width: 768px) {
      width: 97%;
      margin-left: 3%;
    }
    @media screen and (max-width: 500px) {
      width: 95%;
      margin-left: 2%;
    }
    @media screen and (max-width: 450px) {
      display: inline-block;
      width: 70%;
      margin: 0 auto;
    }
    @media screen and (max-width: 380px) {
      width: 80%;
    }
    @media screen and (max-width: 350px) {
      width: 85%;
    }
    @media screen and (max-width: 320px) {
      width: 90%;
    }
    &__clock-container {
      width: 58.14%;
      height: 100px;
      // background: rgb(2, 104, 221);
      display: inline-flex;
      @media screen and (max-width: 768px) {
        height: 69.4px;
        width: 50%;
      }
      @media screen and (max-width: 700px) {
        width: 55%;
      }
      @media screen and (max-width: 580px) {
        width: 58%;
      }
      @media screen and (max-width: 500px) {
        width: 63%;
      }
      @media screen and (max-width: 450px) {
        width: 100%;
        height: 42px;
      }
      &__ballcontainer {
        display: inline-flex;
        // background: brown;
        width: 16.32%;
        height: 100px;
        line-height: 106px;
        justify-content: center;
        align-content: center;
        align-items: center;
        @media screen and (max-width: 768px) {
          height: 69.4px;
          line-height: 75.4px;
        }
        @media screen and (max-width: 450px) {
          height: 42px;
          line-height: 48px;
        }
        &__ball {
          height: 100px;
          margin-top: 14px;
          @media screen and (max-width: 1165px) {
            margin-top: 13px;
          }
          @media screen and (max-width: 1070px) {
            margin-top: 12px;
          }
          @media screen and (max-width: 970px) {
            margin-top: 10px;
          }
          @media screen and (max-width: 870px) {
            margin-top: 7px;
          }
          @media screen and (max-width: 768px) {
            height: 69.4px;
            margin-top: 11px;
          }
          @media screen and (max-width: 650px) {
            margin-top: 8px;
          }
          @media screen and (max-width: 580px) {
            margin-top: 7px;
          }
          @media screen and (max-width: 450px) {
            height: 42px;
            margin-top: 10px;
          }
          @media screen and (max-width: 415px) {
            margin-top: 6px;
          }
          @media screen and (max-width: 380415px) {
            margin-top: 8px;
          }
        }
        &__diamond {
          height: 100px;
          @media screen and (max-width: 768px) {
            height: 69.4px;
          }
          @media screen and (max-width: 450px) {
            height: 42px;
          }
        }
      }
      &__dayscontainer {
        // background: palevioletred;
        width: 19.7%;
        height: 100px;
        display: inline-block;
        &__num {
          width: 100%;
          height: 50px;
          // background: blue;
          font-size: 34px;
          font-family: 'Avenir-Pro-Bold';
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 69px;
          letter-spacing: normal;
          text-align: center;
          color: #aee474;
          @media screen and (max-width: 1024px) {
            font-size: 28px;
            line-height: 74px;
          }
          @media screen and (max-width: 768px) {
            height: 34.7px;
            line-height: 50px;
            font-size: 24px;
          }
          @media screen and (max-width: 450px) {
            height: 21px;
            font-size: 20px;
            line-height: 26px;
          }
          @media screen and (max-width: 320px) {
            height: 21px;
            font-size: 16px;
            line-height: 29px;
          }
        }
        &__leyend {
          width: 100%;
          height: 50px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 38px;
          letter-spacing: normal;
          text-align: center;
          font-family: 'Avenir-Regular';
          color: #fff;
          // background: cyan;
          @media screen and (max-width: 1024px) {
            font-size: 12px;
            line-height: 30px;
          }
          @media screen and (max-width: 768px) {
            height: 34.7px;
            font-size: 10px;
            line-height: 25px;
          }
          @media screen and (max-width: 450px) {
            height: 21px;
            font-size: 10px;
            line-height: 26px;
          }
          @media screen and (max-width: 320px) {
            height: 21px;
            font-size: 8px;
            line-height: 22px;
          }
        }
      }
      &__diamondcontainer {
        // background: purple;
        width: 4.15%;
        height: 100px;
        line-height: 106px;
        @media screen and (max-width: 768px) {
          line-height: 75.4px;
          height: 69.4px;
        }
        @media screen and (max-width: 450px) {
          height: 42px;
          line-height: 48.4px;
        }
      }
      &__hourscontainer {
        // background: rgb(3, 171, 19.72);
        width: 19.7%;
        height: 100px;
        &__num {
          width: 100%;
          height: 50px;
          // background: blue;
          font-size: 34px;
          font-family: 'Avenir-Pro-Bold';
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 69px;
          letter-spacing: normal;
          text-align: center;
          color: #aee474;
          @media screen and (max-width: 1024px) {
            font-size: 28px;
            line-height: 74px;
          }
          @media screen and (max-width: 768px) {
            height: 34.7px;
            line-height: 50px;
            font-size: 24px;
          }
          @media screen and (max-width: 450px) {
            height: 21px;
            font-size: 20px;
            line-height: 26px;
          }
          @media screen and (max-width: 320px) {
            height: 21px;
            font-size: 16px;
            line-height: 29px;
          }
        }
        &__leyend {
          width: 100%;
          height: 50px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 38px;
          letter-spacing: normal;
          text-align: center;
          font-family: 'Avenir-Regular';
          color: #fff;
          @media screen and (max-width: 1024px) {
            font-size: 12px;
            line-height: 30px;
          }
          @media screen and (max-width: 768px) {
            height: 34.7px;
            font-size: 10px;
            line-height: 25px;
          }
          @media screen and (max-width: 450px) {
            height: 21px;
            font-size: 10px;
            line-height: 26px;
          }
          @media screen and (max-width: 320px) {
            height: 21px;
            font-size: 8px;
            line-height: 22px;
          }
        }
      }
      &__minutescontainer {
        // background: magenta;
        width: 19.7%;
        height: 100px;
        &__num {
          width: 100%;
          height: 50px;
          // background: blue;
          font-size: 34px;
          font-family: 'Avenir-Pro-Bold';
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 69px;
          letter-spacing: normal;
          text-align: center;
          color: #aee474;
          @media screen and (max-width: 1024px) {
            font-size: 28px;
            line-height: 74px;
          }
          @media screen and (max-width: 768px) {
            height: 34.7px;
            line-height: 50px;
            font-size: 24px;
          }
          @media screen and (max-width: 450px) {
            height: 21px;
            font-size: 20px;
            line-height: 26px;
          }
          @media screen and (max-width: 320px) {
            height: 21px;
            font-size: 16px;
            line-height: 29px;
          }
        }
        &__leyend {
          width: 100%;
          height: 50px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 38px;
          letter-spacing: normal;
          text-align: center;
          font-family: 'Avenir-Regular';
          color: #fff;
          @media screen and (max-width: 1024px) {
            font-size: 12px;
            line-height: 30px;
          }
          @media screen and (max-width: 768px) {
            height: 34.7px;
            font-size: 10px;
            line-height: 25px;
          }
          @media screen and (max-width: 450px) {
            height: 21px;
            font-size: 10px;
            line-height: 26px;
          }
          @media screen and (max-width: 320px) {
            height: 21px;
            font-size: 8px;
            line-height: 22px;
          }
        }
      }
    }
    &__sponsor-container {
      width: 41.85%;
      height: 100px;
      @media screen and (max-width: 768px) {
        height: 69.4px;
        width: 50%;
        display: inline-flex;
        justify-content: right;
        align-content: right;
        align-items: right;
      }
      @media screen and (max-width: 700px) {
        width: 45%;
      }
      @media screen and (max-width: 580px) {
        width: 42%;
      }
      @media screen and (max-width: 500px) {
        width: 37%;
      }
      @media screen and (max-width: 450px) {
        height: 32.7px;
        width: 100%;
        justify-content: center;
        align-content: center;
        align-items: center;
      }
      // background: purple;
      &__sponsor {
        width: 203px;
        height: 33.7px;
        border-radius: 10px;
        border: dotted 1.3px #aee474;
        font-size: 12px;
        display: inline-flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        font-weight: normal;
        margin-top: 33.3px;
        font-family: 'Avenir-Regular';
        font-stretch: normal;
        font-style: normal;
        line-height: 33.7px;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        @media screen and (max-width: 870px) {
          margin-top: 35.3px;
        }
        @media screen and (max-width: 970px) {
          width: 183px;
          font-size: 11px;
        }
        @media screen and (max-width: 768px) {
          width: 236.7px;
          height: 35.7px;
          font-size: 13px;
          margin-top: 17px;
          margin-right: 24px;
          border-radius: 8px;
        }
        @media screen and (max-width: 700px) {
          width: 203px;
          height: 33.7px;
          border-radius: 10px;
          font-size: 13px;
          margin-top: 19px;
          margin-right: 24px;
        }
        @media screen and (max-width: 580px) {
          margin-right: 15px;
        }
        @media screen and (max-width: 560px) {
          width: 183px;
          font-size: 11px;
          border-radius: 8px;
          margin-right: 10px;
        }
        @media screen and (max-width: 500px) {
          width: 153px;
          font-size: 9px;
          border-radius: 6px;
          margin-right: 10px;
        }
        @media screen and (max-width: 480px) {
          width: 143px;
          margin-right: 5px;
        }
        @media screen and (max-width: 450px) {
          margin-right: 0px;
          margin-top: 0px;
          width: 209px;
          height: 31px;
          line-height: 31px;
          font-size: 11px;
          border-radius: 4px;
        }
        &__bold {
          font-family: 'Avenir-Pro-Bold';
        }
        img {
          height: 30.7px;
          @media screen and (max-width: 768px) {
            height: 32.7px;
          }
          @media screen and (max-width: 700px) {
            height: 31.7px;
          }
          @media screen and (max-width: 450px) {
            height: 28px;
          }
        }
      }
    }
  }
}
</style>
